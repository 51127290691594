<template>
  <div class="plan">
    <demo-modal ref="demoModal" />
    <div class="bookings">
      <h3>{{ subscription.bookings }}</h3>
      <h3>Bookings</h3>
    </div>
    <div class="price">
      <h2>${{ subscription.price }}</h2>
      <p class="per-month">
        {{ subscription.demo ? "starting, per month" : "per month" }}
      </p>
    </div>
    <FormulateInput
      v-if="subscription.demo && !noButtons"
      type="button"
      name="Book a Demo"
      @click="demo"
    />
    <FormulateInput
      v-else-if="subscription.trial && !noButtons"
      type="button"
      name="Start My 15-day Trial"
      @click="purchase"
    />
    <FormulateInput
      v-else-if="!noButtons"
      type="button"
      name="Subscribe"
      @click="purchase"
    />
    <div class="features">
      <p>Access to all features</p>
      <p>Email only</p>
      <p>Upgrades to SMS available</p>
      <p>4% Additional transaction fee</p>
      <p>Top tier support</p>
    </div>
  </div>
</template>

<script>
import DemoModal from "../modals/DemoModal.vue";

export default {
  name: "subscription-tile",
  components: {
    DemoModal
  },
  props: {
    subscription: Object,
    noButtons: { type: Boolean, default: false }
  },
  computed: {
    brandUrl() {
      return this.$route.params.subdomain;
    }
  },
  methods: {
    async purchase() {
      // this.loading = true;

      let stripe_account = process.env.VUE_APP_STRIPE_ACCT;
      let key = process.env.VUE_APP_STRIPE_KEY;

      let stripe = Stripe(key, {
        stripeAccount: stripe_account || "acct_1M6iYs2fikl0zqZs"
      });

      await this.$axios
        .post("/subscriptions/stripe-session", {
          priceId: this.subscription.stripeId,
          brandUrl: this.brandUrl
        })
        .then(({ data }) => {
          // console.log(resp);
          this.$store.commit("loading", false);
          stripe.redirectToCheckout({
            sessionId: data.sessionId
          });
        });
    },
    demo() {
      this.$refs.demoModal.open();
    }
  }
};
</script>

<style lang="less" scoped>
.plan {
  max-width: 300px;
  max-height: 460px;
  border: 1px solid var(--input-border);
  border-radius: 8px;
  background: var(--input-background);
  display: flex;
  flex-wrap: wrap;
  padding: 16px 32px;
  justify-content: center;

  .bookings {
    width: 100%;
    text-align: center;

    h3 {
      color: white;
      margin: 0;
    }
  }

  .price {
    margin: 16px 0;
    h2 {
      font-size: 72px;
      line-height: 100%;
      margin: 0;
    }
    .per-month {
      margin: 0;
      text-align: center;
      color: grey;
    }
  }

  .formulate-input {
    ::v-deep button {
      margin: auto;
      font-weight: bold;
      border-radius: 8px;
      font-size: 1em;
      color: black;
    }
  }

  .features {
    text-align: center;
  }
}
</style>